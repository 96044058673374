<template>
  <div>
    <ul
      v-if="!!venues.length"
      class="venues-list list-group list-group-flush list my--3"
    >
      <li
        v-for="venue in venues"
        :key="venue.image"
        class="list-group-item px-0"
      >
        <div class="row align-items-top">
          <div class="col-2">
            <img alt="" class="avatar avatar-xxl mr-3" :src="venue.thumbnail" />
          </div>
          <div class="col-8">
            <h3 class="mb-0">{{ venue.name }}</h3>
            <h5 v-if="!!venue.address  && venue.address.country_short === 'AU'">
              <i class="fas fa-map-pin"></i> {{ !!venue.address ? venue.address.city : '' }},
              {{ !!venue.address ? venue.address.state : '' }}
            </h5>
            <h5 v-if="!!venue.address && venue.address.country_short === 'GB'">
              <i class="fas fa-map-pin"></i> {{ !!venue.address ? venue.address.address : '' }},
              {{ !!venue.address ? venue.address.state : '' }}
            </h5>
            <p class="description" v-html="venue.description"></p>
            <badge type="secondary" size="md">
              Guests: {{ shouldSelectSpaces ? getGuests(venue) : venue.general_capacity }}
            </badge>
            <badge type="secondary" size="md">Budget: {{ shouldSelectSpaces ? getFee(venue) : venue.budget }}</badge>
            <br/>
            <badge type="secondary" size="md" v-if="shouldSelectSpaces">Spaces: 
            </badge>
            <span class="select-a-space-borderless" v-if="shouldSelectSpaces">
              <el-select @change="selectASpaceForVenue" v-model="isSpaceSelected[venue.id]" placeholder="Select A Space" size="mini">
                <el-option
                  key="-1" label="- Entire Venue -"
                  :value="venue.id + ':entirevenue'"
                >
                </el-option>
                <el-option
                  v-for="(item, key) in venue.rooms"
                  :key="key"
                  :label="key"
                  :value="venue.id + ':' + key">
                </el-option>
              </el-select>
            </span>
          </div>
          <div class="col-2">
            <div
              v-if="isSelected(venue)"
              @click="removeFromList(venue)"
            >
              <badge
                rounded tag="a" class="btn-add"  href="javascript:"
                type="danger">
                Remove
              </badge>
            </div>
            <div v-else @click="addToList(venue)">
              <badge
                rounded tag="a" class="btn-add" href="javascript:"
                :type="addToListLabelActive(venue) ? 'success' : 'secondary'">
                {{addToListLabelComputed(venue)}}
              </badge>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div v-else class="venue-list-detailed__placeholder">
      <p v-if="!isLoading">Venues with this name not found</p>
    </div>
  </div>
</template>

<script>
import {  Select, Option } from 'element-ui';
export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    venues: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
    },
    addToListLabel: {
      type: String,
    },
    selected: {
      type: Array,
    },
    shouldSelectSpaces: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      isSpaceSelected: [],
      spacesSelected: {},
    };
  },
  methods: {
    addToListLabelComputed(venue) {
      if (!this.shouldSelectSpaces || this.spacesSelected[venue.id]) {
        return this.addToListLabel;  
      }
      return "Select Space";
    },
    addToListLabelActive(venue) {
      if (!this.shouldSelectSpaces || this.spacesSelected[venue.id]) {
        return true;  
      }
      return false;
    },
    isSelected: function(venue) {
      let item = this.selected.find((item) => parseInt(item.id) === parseInt(venue.id));
      return !!item;
    },
    removeFromList: function(venue) {
      this.$emit('remove', venue);
    },
    addToList: function(venue) {
      if (this.shouldSelectSpaces && !this.spacesSelected[venue.id]) {
        return false;
      }
      
      if (this.shouldSelectSpaces) {
        let space = this.spacesSelected[venue.id];
        venue['space'] = space !== 'entirevenue' ? space : '';
      } 
      this.$emit('select', venue);
    },
    getFee(venue) {
      let fee = 'N/A';
      let currencySign = '';
      if (venue.address && venue.address.country_short === 'AU' ) {
        currencySign = '$';
      } else if (venue.address && venue.address.country_short === 'GB' ) {
        currencySign = '£';
      } 

      if (venue.default_fee_type ) {
        venue.default_fee_type.forEach(data => {
          if (data.fee_type_from === 'Per Person') {
            fee = 'From ' + currencySign + data.fee_from_value;
          }
        });
      }
      return fee;
    },
    getGuests(venue) {
      let selectedSpace = this.spacesSelected[venue.id];
      let guests = '';
      if (!selectedSpace) {
        return venue.general_capacity;;
      }
      if (selectedSpace === 'entirevenue') {
        return venue.general_capacity;
      }
      let spaces = venue.rooms[selectedSpace].room_guests;
      if (spaces) {
        spaces.forEach(data => {
          if (data.type.toLowerCase() ===  'dinner & dance') {
            guests = 'Dinner & dance ' + data.max_guests + 'PAX';
          }
        });
      } 
      
      return guests;
    },
    selectASpaceForVenue(value) {
      value = value.split(':');
      this.spacesSelected[value[0]] = value[1];
    },
  },
};
</script>
<style>
.select-a-space-borderless .el-input__inner {
  border: none !important;
}
</style>