<template>
  <div v-loading="venues.loading" class="venues-list">
    <venues-map-view
      @select="addToQueue" @remove="removeFromQueue" :selected="recommendations"
      :venues="venues" addToListLabel="Add To Queue" :shouldSelectSpaces="true"></venues-map-view>
  </div>
</template>
<script>
import VenuesMapView from "@/views/Widgets/Venues/VenuesMapView.vue";
export default {
  components: {
    VenuesMapView,
  },
  props: {
    venues: {
      type: Array,
    },
    leadId: {
      required: true,
    },
    recommendations: {
      type: Array,
    },
  },
  methods: {
    addToQueue(venue) {
      this.recommendations.push(venue);
      this.$emit('update:recommendations' , this.recommendations);
    },
    removeFromQueue(venue) {
      var recommendations = this.recommendations.filter(function(item) {
        return parseInt(item.id) !== parseInt(venue.id);
      });
      this.$emit('update:recommendations' , recommendations);
    },
  },
};

</script>