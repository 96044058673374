<template>
  <div :class="shouldSelectSpaces ? 'venues-list  venue-map-view-with-spaces' : 'venues-list'">
    <GmapMap
      :center="center"
      ref="mapRef"
      :zoom="12"
      map-type-id="roadmap"
      :options="options"
      style="width: 100%; height: 600px"
    >
      <GmapMarker
        :key="index"
        v-for="(venue, index) in venues"
        :position="getCoords(venue)"
        :clickable="true"
        :icon="getMarker(venue)"
        @click="openInfoWindow(venue)"
      />

      <gmap-info-window
        v-if="selectedLocation !== null"
        :position="infoWindowPos"
        :opened="infoBoxOpen"
        :options="{
          pixelOffset: {
            width: 0,
            height: -35,
          },
        }"
        @closeclick="infoBoxOpen = false"
      >
        <div class="info-window">
          <div class="img-wrapper">
            <img :src="selectedLocation.thumbnail" />
          </div>
          <div class="info-window-body">
            <h3 class="truncate">{{ selectedLocation.name }}</h3>
            <h4 class="m-b-2">
              <i class="fas fa-map-pin"></i> {{ getLocation(selectedLocation) }}
            </h4>
            <el-select 
              v-if="shouldSelectSpaces"
              @change="selectASpaceForVenue" v-model="isSpaceSelected[selectedLocation.id]" placeholder="Select A Space" size="mini">
              <el-option
                key="-1" label="- Entire Venue -"
                :value="selectedLocation.id + ':entirevenue'"
              >
              </el-option>
              <el-option
                v-for="(item, key) in selectedLocation.rooms"
                :key="key"
                :label="key"
                :value="selectedLocation.id + ':' + key">
              </el-option>
            </el-select>
            <button
              :class="`btn btn-block btn-outline-${addBtnClass(selectedLocation) }`"
              @click="updateSelected(selectedLocation)"
            >
              {{ selectedLocation.btnText }}
            </button>
          </div>
        </div>
      </gmap-info-window>
    </GmapMap>
  </div>
</template>

<script>
import {  Select, Option } from 'element-ui';
export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    venues: {
      type: Array,
    },
    selected: {
      type: Array,
    },
    addToListLabel: {
      type: String,
    },
    shouldSelectSpaces: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSpaceSelected: [],
      spacesSelected: {},
      googlemap: null,
      infoWindowPos: null,
      infoWinOpen: false,
      infoOptions: {
        content: "",
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      center: { lat: -37.8136, lng: 144.9631 },
      selectedLocation: null,
      infoBoxOpen: false,
      options: {
        styles: [
          {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "road",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "transit",
            stylers: [{ visibility: "off" }],
          },
        ],
      },
    };
  },
  mounted() {
  // At this point, the child GmapMap has been mounted, but
  // its map has not been initialized.
  // Therefore we need to write mapRef.$mapPromise.then(() => ...)
  
    this.$refs.mapRef.$mapPromise.then((map) => {
      const bounds = new google.maps.LatLngBounds();
      for (let venue of this.selected) {
        venue.position = {
          lat: parseFloat(this.getCoords(venue).lat),
          lng: parseFloat(this.getCoords(venue).lng),
        };
        bounds.extend(venue.position);
        this.center = bounds.getCenter();
      }
      map.fitBounds(bounds);
    });
  },
  methods: {
    isSelected: function (venue) {
      let item = this.selected.find(
        (item) => parseInt(item.id) === parseInt(venue.id)
      );
      return !!item;
    },
    getCoords(venue) {
      let lat = venue.lat || 0;
      let lng = venue.lng || 0;
      return {
        lat: parseFloat(venue.address ? venue.address.lat : lat),
        lng: parseFloat(venue.address ? venue.address.lng : lng),
      };
    },
    getMarker(venue) {
      if (this.isSelected(venue)) {
        venue.position = {
          lat: parseFloat(this.getCoords(venue).lat),
          lng: parseFloat(this.getCoords(venue).lng),
        };
        return "http://maps.google.com/mapfiles/ms/icons/green-dot.png";
      } else {
        return "http://maps.google.com/mapfiles/ms/icons/red-dot.png";
      }
    },
    updateSelected(selectedLocation) {
      if (this.shouldSelectSpaces && !this.spacesSelected[selectedLocation.id]) {
        return false;
      }
      if (this.shouldSelectSpaces) {
        let space = this.spacesSelected[selectedLocation.id];
        selectedLocation['space'] = space !== 'entirevenue' ? space : '';
      } 
      if (!!selectedLocation.selected) {
        this.$emit("remove", selectedLocation);
      }
      if (!selectedLocation.selected) {
        this.$emit("select", selectedLocation);
      }
      this.closeInfoWindow();
    },
    openInfoWindow(venue) {
      this.infoWindowPos = this.getCoords(venue);
      this.selectedLocation = venue;
      this.infoBoxOpen = true;

      if (this.isSelected(venue)) {
        this.selectedLocation.selected = true;
        this.selectedLocation.btnText = "Remove";
      } else {
        this.selectedLocation.selected = false;
        this.selectedLocation.btnText = this.addToListLabel;
      }
    },
    addBtnClass(venue) {
      if (this.isSelected(venue)) {
        return "danger";
      } else {
        if (!this.shouldSelectSpaces || this.spacesSelected[venue.id]) {
          return "primary";
        } else {
          return "light";
        }
      }
    },
    closeInfoWindow() {
      this.infoBoxOpen = false;
    },
    getLocation(venue) {
      if (venue.address && venue.address.country_short === "AU") {
        return venue.address.city + ", " + venue.address.state;
      } else if (venue.address && venue.address.country_short === "UK") {
        return venue.address.address + ", " + venue.address.state;
      } else {
        return venue.address ? venue.address.state : "";
      }
    },
    selectASpaceForVenue(value) {
      value = value.split(':');
      this.spacesSelected[value[0]] = value[1];
    },
  },
};
</script>

<style lang="scss">
.venues-list {
  h4 {
    font-weight: normal;
  }

  img {
    width: 100%;
  }

  .thumbnail img {
    max-width: 125px;
  }
  .description {
    font-size: 14px;
  }

  .info-window {
    width: 250px;
    padding: 0;
  }
  .info-window-body {
    padding: 8px;
  }

  .img-wrapper {
    height: 180px;
    overflow: hidden;
  }

  .gm-style-iw {
    padding: 0 !important;
    height: 300px;
  }

  .gm-style-iw-d {
    overflow: auto !important;
  }

  .truncate {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.venue-map-view-with-spaces {
  .gm-style-iw {
    height: 380px;
  }
  .info-window {
    .el-select {
      width: 100%;
      margin-bottom: 10px !important;
    }
  }
}
</style>
