<template>
  <div class="venue-list-detailed" v-loading="loading">
    <venues-list-view
      :isLoading="loading" @select="addToQueue" @remove="removeFromQueue" :selected="recommendations"
      :venues="venues" addToListLabel="Add To Queue" :shouldSelectSpaces="true"></venues-list-view>
  </div>
</template>

<script>
import VenuesListView from "@/views/Widgets/Venues/VenuesListView.vue";
export default {
  name: "venue-list-detailed",
  data() {
    return {
      loading: true,
    };
  },
  components: {
    VenuesListView,
  },
  props: {
    leadId: {
      required: true,
    },
    venues: {
      type: Array,
      default: () => [],
    },
    recommendations: {
      type: Array,
    },
  },
  async created() {
    await this.$store.dispatch("venues/getAll", {lead_id: this.leadId});
    this.loading = false;
  },
  methods: {
    addToQueue(venue) {
      this.recommendations.push(venue);
      this.$emit('update:recommendations' , this.recommendations);
    },
    removeFromQueue(venue) {
      var recommendations = this.recommendations.filter(function(item) {
        return parseInt(item.id) !== parseInt(venue.id);
      });
      this.$emit('update:recommendations' , recommendations);
    },
  },
};
</script>

<style>
.venues-list .thumbnail img {
  max-width: 125px;
}
.venues-list .description {
  font-size: 14px;
}

.venues-list .avatar-xxl {
  width: 100%;
  height: auto;
}
</style>
