export default {
  data() {
    return {
      searchQuery: '',
      country: '',
    };
  },

  computed: {
    // filter for all venues
    // This method sends an array to a component that displays data
    filteredVenuesData() {
      let result = this.venues;
      result = this.filterByCountry(result, this.country);
      result = this.filterByQuery(result, this.searchQuery);

      return result;
    },
  },
  methods: {
    filterByCountry(result, country) {
      if (country !== '' && country.toLowerCase() !== 'all') {
        result = result.filter((item) => item.country.toLowerCase().includes(country.toLowerCase()));
      }
      return result;
    },
    filterByQuery(result, query) {
      let key = this.propsToSearch || 'name';
      if (query !== '') {
        result = result.filter((item) => item[key].toLowerCase().includes(query.toLowerCase()));
      }
      return result;
    },
  },
  mounted() {},
};
