<template>
  <div class="filters">
    <!-- city select -->
    <div class="row">
      <div class="col-lg-12">
        <base-input label="Event Location">
          <el-select @change="changeCountry" v-model="filterData.country" filterable placeholder="Country">
            <el-option
              v-for="option in locationOptions"
              :key="option.label"
              :label="option.label"
              :value="option.value">
            </el-option>
          </el-select>
        </base-input>
      </div>
      <!-- city select -->

      <!--
      <div class="col-lg-12">
        <base-input label="Event Type">
          <el-select v-model="selects.type" multiple filterable placeholder="Event Type">
            <el-option
              v-for="option in typeOptions"
              :key="option.label"
              :label="option.label"
              :value="option.value"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-lg-12">
        <base-input label="Event Category">
          <el-select v-model="selects.category" multiple filterable placeholder="Event Type">
            <el-option
              v-for="option in categoryOptions"
              :key="option.label"
              :label="option.label"
              :value="option.value"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-lg-12">
        <base-input
          type="text"
          label="Estimated Budget"
          placeholder="Estimated Budget"
          v-model="filter.budget"
        >
          <template slot="prepend">$</template>
        </base-input>
      </div>
      <div class="col-lg-12">
        <base-input
          type="text"
          label="Estimated Guests"
          placeholder="Estimated Guests"
          v-model="filter.guests"
        >
          <template slot="prepend"><i class="ni ni-single-02"></i></template>
        </base-input>
      </div>
    -->
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>

<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import baseInput from '@/components/Inputs/BaseInput.vue';
import Tabs from "@/components/Tabs/Tabs";
import Tab from "@/components/Tabs/Tab";

export default {
  props: {
    venues: {
      type: Array,
    },
    country: {
      type: String,
      default: "ALL",
    },
  },
  created() {
    this.filterData.country = this.country;
  },
  components: {
    baseInput,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  methods: {
    changeCountry(country) {
      // // Pass data to parent
      this.$emit('update:country', country);
    },
  },
  data() {
    return {
      filterData: {
        country: '',
      },
      locationOptions: [
        {
          label: 'Australia',
          value: 'AU',
        },
        {
          label: 'United Kingdom',
          value: 'UK',
        },
      ],
      typeOptions: [
        {
          label: 'Wedding',
          value: 'Wedding',
        },
        {
          label: 'Social',
          value: 'Social',
        },
        {
          label: 'Corporate',
          value: 'Corporate',
        },
      ],
      categoryOptions: [
        {
          label: 'Beach',
          value: 'Beach',
        },
        {
          label: 'Classic',
          value: 'Classic',
        },
        {
          label: 'Rustic',
          value: 'Rustic',
        },

        {
          label: 'Garden',
          value: 'Garden',
        },
        {
          label: 'Rooftop',
          value: 'Rooftop',
        },
        {
          label: 'Waterfront',
          value: 'Waterfront',
        },
      ],
    };
  },
};
</script>

